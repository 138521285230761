<template>
	<div>
		<Navbar></Navbar>
		<main class="bg-main">
			<div class="form-container center-align bg-main">
				<form
					@submit.prevent="submitForm"
					class="container row contact-form"
					id="formContacto"
				>
					<div>
						<h3>¡Contáctanos!</h3>
						<p>Si tienes alguna duda o sugerencia, por favor, escríbenos.</p>
					</div>
					<div class="input-field col s12 m4">
						<input
							type="text"
							name="name"
							id="name"
							v-model="form.name"
							required
						/>
						<label for="name">Nombre</label>
					</div>
					<div class="input-field col s12 m4">
						<input
							type="text"
							name="mail"
							id="mail"
							v-model="form.mail"
							required
						/>
						<label for="mail">Correo electrónico</label>
					</div>
					<div class="input-field col s12 m4">
						<input
							type="text"
							name="phone"
							id="phone"
							v-model="form.phone"
							required
						/>
						<label for="phone">N. Teléfonico</label>
					</div>
					<div class="input-field col s12">
						<textarea
							name="msg"
							id="message"
							class="materialize-textarea"
							v-model="form.message"
							required
						></textarea>
						<label for="message">Mensaje (max 1000 caracteres)</label>
					</div>
					<div class="col s12 right-align">
						<button class="btn deep-purple darken-2" type="submit">
							Enviar
							<i class="material-icons right">send</i>
						</button>
					</div>
					<div
						class="g-recaptcha"
						data-sitekey="6Leinp8eAAAAAKqk74FTEi7Neb6rnwqtscIocgbJ"
						data-callback="fiexactSubmit"
						data-size="invisible"
					></div>
				</form>
			</div>
		</main>
		<Footer></Footer>
	</div>
</template>
<script>
import Navbar from "../components/navbar.vue";
import Footer from "../components/footer.vue";
import axios from "../assets/axios.js";
export default {
	components: {
		Navbar,
		Footer,
	},
	data() {
		return {
			form: {
				name: null,
				mail: null,
				phone: null,
				message: null,
			},
		};
	},
	methods: {
		submitForm: function fxevt(evt) {
			evt.preventDefault();
			window.fiexactSubmit = (evt) => {
				const config = {
					responseType: "text",
				};
				//Envío de datos del formulario para enviar el correo
				axios
					.post(
						"/mailhandler.php",
						{
							name: this.form.name,
							mail: this.form.mail,
							phone: this.form.phone,
							message: this.form.message,
							"g-recaptcha-response": evt,
						},
						config
					)
					.then((response) => {
						if (response.data.success == true) {
							Swal.fire({
								title: "¡Gracias por tu interés!",
								text: "Nos pondremos en contacto contigo lo más pronto posible.",
								icon: "success",
								confirmButtonText: "Cerrar",
								confirmButtonColor: "#512da8",
							});
							this.form.name = "";
							this.form.mail = "";
							this.form.phone = "";
							this.form.message = "";
						} else {
							Swal.fire({
								title: response.data.errorTitle,
								text: response.data.errorMessage,
								icon: "error",
								confirmButtonText: "Cerrar",
								confirmButtonColor: "#00837a",
							});
						}
					})
					.catch((error) => {
						Swal.fire({
							title: "Error",
							text: "Ocurrió un error al enviar el formulario, por favor intenta de nuevo.",
							icon: "error",
							confirmButtonText: "Cerrar",
							confirmButtonColor: "#00837a",
						});
						console.log(error);
					});
			};
			grecaptcha.execute();
		},
	},
};
</script>

<style scoped>
#app > div {
	display: flex;
	min-height: 100vh;
	flex-direction: column;
}

main {
	flex: 1 0 auto;
	display: table;
}

.form-container {
	display: table-cell;
	vertical-align: middle;
	/* background: cyan; */
}

.contact-form {
	background: white;
	padding: 1ex 2em;
	border-radius: 1em;
}
</style>
