<template>
  <div id="home">
    <Navbar></Navbar>
    <main>
      <div class="parallax-container" style="height: 80vh">
        <div class="parallax-text">
          <div>
            <div class="vertical-center">
              <h1
                style="display: inline-block; font-size: 5rem"
                class="left-align"
              >
                Vivimos en la terminal;
              </h1>
            </div>
          </div>
        </div>
        <div class="parallax">
          <img src="img/IMG_20220211_160526.webp" style="width: 100%" />
        </div>
      </div>

      <div class="parallax-container container" style="height: 200px">
        <div
          class="parallax-text"
          style="background: transparent; color: inherit"
        >
          <div>
            <div class="vertical-center left-align">
              <h2>
                En fiexact_ desarrollamos soluciones a la medida considerando
                cada aspecto de tus necesidades.<br />
                Trabajamos con tecnologías punteras y seguro encontraremos una
                solución que se adapte a ti, no a los límites del entorno.
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div class="container stack-list center-align">
        <h1 class="left-align">Nuestro stack</h1>
        <figure>
          <span><img src="/img/icon/apache.svg" />Apache web server</span>
        </figure>
        <figure>
          <span><img src="/img/icon/arango.svg" />Arango DB</span>
        </figure>
        <figure>
          <span><img src="/img/icon/laravel.svg" />Laravel</span>
        </figure>
        <figure>
          <span><img src="/img/icon/mariadb.svg" />Maria DB</span>
        </figure>
        <figure>
          <span><img src="/img/icon/mongodb.svg" />Mongo DB</span>
        </figure>
        <figure>
          <span><img src="/img/icon/nginx.svg" />Nginx</span>
        </figure>
        <figure>
          <span><img src="/img/icon/nodejs-icon.svg" />NodeJS</span>
        </figure>
        <figure>
          <span><img src="/img/icon/php.svg" />PHP 8</span>
        </figure>
        <figure>
          <span><img src="/img/icon/python.svg" />Python</span>
        </figure>
        <figure>
          <span><img src="/img/icon/tux.svg" />Linux</span>
        </figure>
      </div>
    </main>
    <Footer></Footer>
  </div>
</template>
<script>
//import Cards from "../components/cards.vue";
//import Tables from "../components/tables.vue";
import Navbar from "../components/navbar.vue";
import Footer from "../components/footer.vue";
export default {
  name: "home",
  components: {
    // Cards,
    //Tables,
    Navbar,
    Footer,
  },
};
</script>
<style scoped>
.stack-list figure {
  display: inline-table;
  height: 100px;
  width: 100px;
  margin: 0.5ex 1em;
}
.stack-list figure span {
  display: table-cell;
  vertical-align: bottom;
  text-align: center;
}
.stack-list figure img {
  height: 100px;
  width: 100px;
  object-fit: contain;
}
</style>
