import en from './assets/en.json';
import es from './assets/es.json';
import VueI18n from 'vue-i18n';
import Vue from 'vue';

Vue.use(VueI18n);

export default new VueI18n({

  locale: localStorage.getItem('lang') || 'es',
  fallbackLocale:'us',
  messages:{
    en:en,
    es:es
  }
})
