<template>
  <div>
    <div style="text-align: center">
      <span
        class="iconify icons-caracteristica"
        :data-icon="proyecto.icono"
      ></span>
    </div>
    <h3>{{ $t(proyecto.name) }}</h3>
    <p id="textA-style">{{ $t(proyecto.txt) }}</p>
  </div>
</template>

<script>
export default {
  name: "caracteristicas-grid",
  props: {
    proyecto: {
      type: Object,
    },
  },
};
</script>

<style scoped>
.icons-caracteristica {
  font-size: 5rem;
}
#textA-style {
  font-size: 1.3rem;
  line-height: 1.8;
}
</style>