<template>
	<div class="us-about change" id="documentation">
		<Navbar></Navbar>
		<main>
		<!--	<div class="container">
				 <div class="nosotros-texto1 row">
          <div class="col s12 m12 l12">
            <h5 v-t="'nosotros.texto1'"></h5>
          </div>
        </div>
				<div class="nosotros-texto1 row">
					<div class="col s12 m12 l12">
						<h5>
							{{ $t("nosotros.quienes_somos_texto") }} <br /><br />
							 <a
								style="border-radius: 10px"
								class="waves-effect waves-light btn btn-local"
								>{{ $t("nosotros.boton") }}</a>
						</h5>
					</div>
				</div>
			</div>
		-->
			<div class="container main-grid">
				<Cardmega
					class="mega-grid"
					:card="card"
					v-for="card in cardmega"
					:key="card.titulo"
				></Cardmega>

				<div class="team-grid">
					<Cards
						:card="card"
						v-for="card in cardsteam"
						:key="card.titulo"
					></Cards>
				</div>
			</div>
			<div class="container">
				<!-- <div class="row linea marge">
          <div class="col s12 m4">
            <h5 v-t="'nosotros.quienes_somos_titulo'"></h5>
          </div>
          <div class="col s12 m8">
            <p>
              {{ $t("nosotros.quienes_somos_texto") }} <br /><br />
              <a
                style="border-radius: 10px"
                class="waves-effect waves-light btn btn-local"
                >{{ $t("nosotros.boton") }}</a
              >
            </p>
          </div>
        </div> -->
				<div class="row marge linea">
					<sequential-entrance delay="200">
						<div class="col s12 m4">
							<h5 v-t="'nosotros.que_nos_diferencia'"></h5>
						</div>
						<div class="col s12 m8">
							<sequential-entrance delay="200">
								<div class="row">
									<div class="col s12 m12">
										<h6 v-t="'nosotros.que_nos_diferencia_creamos_equipo'"></h6>
										<p
											v-t="'nosotros.que_nos_diferencia_creamos_equipo_texto'"
										></p>
									</div>
								</div>
								<div class="row">
									<div class="col s12 m12">
										<h6
											v-t="'nosotros.que_nos_diferencia_trabajamos_diferente'"
										></h6>
										<p
											v-t="
												'nosotros.que_nos_diferencia_trabajamos_diferente_texto'
											"
										></p>
									</div>
								</div>
								<div class="row">
									<div class="col s12 m12">
										<h6
											v-t="'nosotros.que_nos_diferencia_disenio_importa'"
										></h6>
										<p
											v-t="'nosotros.que_nos_diferencia_disenio_importa_texto'"
										></p>
									</div>
								</div>
								<div class="row">
									<div class="col s12 m12">
										<h6 v-t="'nosotros.que_nos_diferencia_escalabilidad'"></h6>
										<p
											v-t="'nosotros.que_nos_diferencia_escalabilidad_texto'"
										></p>
									</div>
								</div>
							</sequential-entrance>
						</div>
					</sequential-entrance>
				</div>
				<div
					class="
						row
						marge
						animate__animated animate__fadeLeft animate__delay-2s animate__slow
					"
				>
					<sequential-entrance fromLeft>
						<div class="col s12 m8">
							<sequential-entrance fromLeft>
								<div class="row">
									<div class="col s12 m12">
										<h6
											class="right-align"
											v-t="'nosotros.fortalezas_personas_Empresa'"
										></h6>
										<p
											class="right-align"
											v-t="'nosotros.fortalezas_personas_Empresa_texto'"
										></p>
									</div>
								</div>
								<div class="row">
									<div class="col s12 m12">
										<h6
											class="right-align"
											v-t="'nosotros.fortalezas_servicio'"
										></h6>
										<p
											class="right-align"
											v-t="'nosotros.fortalezas_servicio_texto'"
										></p>
									</div>
								</div>
								<div class="row">
									<div class="col s12 m12">
										<h6 class="right-align" v-t="'nosotros.fortalezas_actitud'">
											Actitud positiva
										</h6>
										<p
											class="right-align"
											v-t="'nosotros.fortalezas_actitud_texto'"
										></p>
									</div>
								</div>
								<div class="row">
									<div class="col s12 m12">
										<h6
											class="right-align"
											v-t="'nosotros.fortalezas_agilidad'"
										>
											Agilidad
										</h6>
										<p
											class="right-align"
											v-t="'nosotros.fortalezas_agilidad_texto'"
										></p>
									</div>
								</div>
							</sequential-entrance>
						</div>
						<div class="col s12 m4">
							<h5 class="right-align" v-t="'nosotros.fortalezas'">
								Nuestras fortalezas?
							</h5>
						</div>
					</sequential-entrance>
				</div>
			</div>
		</main>
		<Footer></Footer>
	</div>
</template>

<script>
import Navbar from "../components/navbar.vue";
import Cards from "../components/cards-team.vue";
import Cardmega from "../components/card-mega.vue";
import Footer from "../components/footer.vue";
export default {
	data() {
		return {
			cardmega: [
				{
					codigo: 1,
					nombre: "cardmega.1.nombre",
					titulo: "cardmega.1.titulo",
					imagen: "../img/equipo/vicente.webp",
					alt: "cardmega.1.alt",
					texto: "cardmega.1.texto",
					textoa: "cardmega.1.textoa",
					texto2: "cardmega.1.texto2",
				},
			],
			cardsteam: [
				{
					codigo: 1,
					nombre: "cardsteam.1.nombre",
					titulo: "cardsteam.1.titulo",
					imagen: "../img/equipo/nathan.webp",
					alt: "cardsteam.1.alt",
					texto: "cardsteam.1.texto",
				},
				{
					codigo: 2,
					nombre: "cardsteam.2.nombre",
					titulo: "cardsteam.2.titulo",
					imagen: "../img/equipo/rodrigo.jpg",
					alt: "cardsteam.2.alt",
					texto: "cardsteam.2.texto",
				},
				{
					codigo: 3,
					nombre: "cardsteam.3.nombre",
					titulo: "cardsteam.3.titulo",
					imagen: "../img/equipo/erick.jpg",
					alt: "cardsteam.3.alt",
					texto: "cardsteam.3.texto",
				},
				{
					codigo: 4,
					nombre: "cardsteam.4.nombre",
					titulo: "cardsteam.4.titulo",
					imagen: "../img/equipo/eduardo.jpg",
					alt: "cardsteam.4.alt",
					texto: "cardsteam.4.texto",
				},
				{
					codigo: 5,
					nombre: "cardsteam.5.nombre",
					titulo: "cardsteam.5.titulo",
					imagen: "../img/equipo/carlos.jpg",
					alt: "cardsteam.5.alt",
					texto: "cardsteam.5.texto",
				},
				{
					codigo: 6,
					nombre: "cardsteam.6.nombre",
					titulo: "cardsteam.6.titulo",
					imagen: "../img/equipo/alan.jpg",
					alt: "cardsteam.6.alt",
					texto: "cardsteam.6.texto",
				},
				{
					codigo: 7,
					nombre: "cardsteam.7.nombre",
					titulo: "cardsteam.7.titulo",
					imagen: "../img/equipo/yahir.jpg",
					alt: "cardsteam.7.alt",
					texto: "cardsteam.7.texto",
				},
			],
		};
	},
	props: {},
	name: "about",
	components: {
		Navbar,
		Cards,
		Cardmega,
		Footer,
	},
};
</script>

<style scoped>
.container-2 {
	inline-size: 100%;
}
h5 {
	color: rgb(255, 255, 255);
	font-family: "Cascadia Code";
}

h6 {
	color: rgb(255, 255, 255);
}
p {
	color: rgb(255, 255, 255);
	font-size: 1.1rem;
	font-family: "Cascadia Code";
	text-shadow: 0 0 1px rgb(0, 0, 0);
}
.linea {
	border-block-end: 1px solid #9999cc;
}

.marge {
	margin-block-start: 2.5rem;
}
.main-grid,
.team-grid,
.mega-grid {
	display: grid;
}
.team-grid {
	grid-auto-rows: minmax(min(100%, 10rem), 20);
	grid-template-columns: repeat(auto-fill, minmax(min(100%, 15.8rem), 1fr));
	grid-gap: 15px;
	margin: 0 15px;
}
.mega-grid {
	grid-auto-rows: minmax(min(100%, 20rem), 30rem);
	grid-template-columns: repeat(auto-fill, minmax(min(100%, 50%), 1fr));
	grid-auto-flow: dense;
	grid-gap: 1rem;
	margin: 0 15px;
}

.card {
	block-size: 18rem;
	background: linear-gradient(to bottom right, #b295f3, #3a1b8f);
}
.us-about {
	background-image: linear-gradient(
		140deg,
		hsl(272deg 58% 36%) 0%,
		hsl(272deg 51% 40%) 8%,
		hsl(272deg 45% 45%) 15%,
		hsl(272deg 41% 49%) 23%,
		hsl(272deg 42% 53%) 29%,
		hsl(272deg 45% 57%) 35%,
		hsl(272deg 49% 61%) 39%,
		hsl(272deg 54% 65%) 43%,
		hsl(272deg 61% 70%) 46%,
		hsl(272deg 69% 74%) 48%,
		hsl(272deg 82% 78%) 51%,
		hsl(272deg 100% 83%) 54%,
		hsl(272deg 80% 78%) 57%,
		hsl(272deg 66% 73%) 61%,
		hsl(272deg 57% 68%) 65%,
		hsl(272deg 51% 64%) 69%,
		hsl(272deg 45% 59%) 73%,
		hsl(272deg 41% 55%) 77%,
		hsl(272deg 38% 50%) 81%,
		hsl(272deg 42% 46%) 86%,
		hsl(272deg 47% 41%) 90%,
		hsl(272deg 53% 37%) 95%,
		hsl(272deg 61% 32%) 100%
	);

	background-size: 100% 300%;
	animation: change 10s ease;
	-webkit-animation: change 10s ease;
	-moz-animation: change 10s ease;
}

@-webkit-keyframes change {
	0% {
		background-position: 86% 100%;
	}
	50% {
		background-position: 15% 0%;
	}
	100% {
		background-position: 15% 0%;
	}
}
@-moz-keyframes change {
	0% {
		background-position: 86% 100%;
	}
	50% {
		background-position: 15% 0%;
	}
	100% {
		background-position: 15% 0%;
	}
}
@keyframes change {
	0% {
		background-position: 86% 100%;
	}
	50% {
		background-position: 15% 0%;
	}
	100% {
		background-position: 15% 0%;
	}
}

.nosotros-texto1 {
	margin-block-start: 10px;
}
</style>
