<template>
  <div class="us-about change" id="documentation">
    <Navbar></Navbar>
    <main>
      <div class="container">
        <sequential-entrance delay="200">
          <div class="row margen-container primera-seccion">
            <div class="col s12 m6">
              <h1 v-t="'proyectos.titulo'"></h1>
            </div>
            <div class="col s21 m12">
              <p id="text1-style" v-t="'proyectos.texto'"></p>
            </div>
          </div>
        </sequential-entrance>

        <sequential-entrance delay="200">
          <div class="row">
            <div class="col s12 m6">
              <h1 v-t="'caracteristica.titulo'"></h1>
            </div>
          </div>
        </sequential-entrance>
        <sequential-entrance delay="200">
          <div class="grid-4cols">
            <Caractgrid
              :proyecto="proyecto"
              v-for="proyecto in proyectos"
              :key="proyecto.codigo"
            />
          </div>
        </sequential-entrance>
      </div>
      <div class="container2">
        <div class="row margen-container">
          <div class="col m6">
            <sequential-entrance fromLeft delay="200">
              <div
                class="
                  col
                  s12
                  m12
                  marge
                  animate__animated
                  animate__fadeLeft
                  animate__delay-2s
                  animate__slow
                "
              >
                <div class="row">
                  <div class="col">
                    <h2
                      style="
                        font-size: 2.4em;
                        line-height: 1em;
                        text-align: center;
                      "
                      v-t="'proyectos.titulo1'"
                    ></h2>
                  </div>
                  <div class="col">
                    <div id="card-img">
                      <img
                        src="img/projects/panel-admin.png"
                        width="100%"
                        alt=""
                        class="imagen-efecto panel-admin"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </sequential-entrance>
          </div>
          <div style="height: 32rem" class="col m6 text-panel-admin">
            <sequential-entrance fromRigth delay="200">
              <i18n id="text-special-style" tag="p" path="proyectos.proyecto1">
                <!-- <template #link>
                      <a href="#"><b v-t="'proyectos.link'"></b></a>
                    </template> -->
              </i18n>
            </sequential-entrance>
          </div>
        </div>
        <div class="row margen-container">
          <div style="height: 32rem" class="col m6 text-panel-admin">
            <sequential-entrance fromLeft delay="300">
              <i18n id="text-special-style" tag="p" path="proyectos.proyecto2">
              </i18n>
            </sequential-entrance>
          </div>
          <div class="col m6">
            <sequential-entrance fromRight delay="300">
              <div
                class="
                  col
                  s12
                  m12
                  marge
                  animate__animated
                  animate__fadeRight
                  animate__delay-4s
                  animate__slow
                "
              >
                <div class="row">
                  <div class="col s12">
                    <h3 v-t="'proyectos.titulo2'"></h3>
                    <div id="card-img">
                      <img
                        src="img/projects/reporte.png"
                        width="100%"
                        alt=""
                        class="imagen-efecto panel-admin"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </sequential-entrance>
          </div>
        </div>
      </div>
      <div class="container">
        <sequential-entrance delay="200">
          <div class="row">
            <div class="col s12 m6">
              <h1 v-t="'condicion.titulo'"></h1>
            </div>
          </div>
        </sequential-entrance>
        <sequential-entrance delay="200">
          <div class="grid-4cols">
            <Condiciongrid
              :condicion="condicion"
              v-for="condicion in condiciones"
              :key="condicion.codigo"
            />
          </div>
        </sequential-entrance>
      </div>
      <div class="container3">
        <!-- row primeros dos proyectos-->
        <div class="row margen-container">
          <sequential-entrance fromBottom>
            <div
              class="
                col
                s12
                m3
                marge
                animate__animated
                animate__fadeBottom
                animate__delay-4s
                animate__slow
              "
            >
              <div class="row">
                <div class="col s12">
                  <h3 v-t="'proyectos.titulo3'"></h3>
                  <div id="card-img">
                    <img
                      src="img/projects/product-masive.png"
                      width="100%"
                      alt=""
                      class="imagen-efecto panel-admin"
                    />
                  </div>
                </div>
                <div class="col s12">
                  <i18n tag="p" path="proyectos.proyecto3">
                    <template #link>
                      <a href="#"><b v-t="'proyectos.link'"></b></a>
                    </template>
                  </i18n>
                </div>
              </div>
            </div>
            <div
              class="
                col
                s12
                m6
                marge
                animate__animated
                animate__fadeBottom
                animate__delay-14s
                animate__slow
              "
            >
              <div class="row">
                <div class="col s12">
                  <h3 v-t="'proyectos.titulo4'"></h3>
                  <div id="card-img">
                    <img
                      src="img/projects/tarjetas-virtuales.png"
                      width="100%"
                      alt=""
                      class="imagen-efecto panel-admin"
                    />
                  </div>
                </div>
                <div class="col s12">
                  <i18n tag="p" path="proyectos.proyecto4">
                    <template #link>
                      <a href="#"><b v-t="'proyectos.link'"></b></a>
                    </template>
                  </i18n>
                </div>
              </div>
            </div>

            <div
              class="
                col
                s12
                m3
                marge
                animate__animated
                animate__fadeBottom
                animate__delay-10s
                animate__slow
              "
            >
              <div class="row">
                <div class="col s12">
                  <h3 v-t="'proyectos.titulo5'"></h3>
                  <div id="card-img5">
                    <img
                      src="img/projects/stats.png"
                      width="100%"
                      alt=""
                      class="imagen-efecto panel-admin"
                    />
                  </div>
                </div>
                <div class="col s12">
                  <i18n tag="p" path="proyectos.proyecto5">
                    <template #link>
                      <a href="#"><b v-t="'proyectos.link'"></b></a>
                    </template>
                  </i18n>
                </div>
              </div>
            </div>
          </sequential-entrance>
        </div>
      </div>
      <Footer></Footer>
    </main>
  </div>
</template>

<script>
import Navbar from "../components/navbar.vue";
import Caractgrid from "../components/caracteristicas-grid.vue";
import Condiciongrid from "../components/condiciones-grid.vue";
import Footer from "../components/footer.vue";
export default {
  name: "projects",
  components: {
    Navbar,
    Footer,
    Caractgrid,
    Condiciongrid,
  },

  data() {
    return {
      proyectos: [
        {
          codigo: 1,
          name: "caracteristica.1.name",
          icono: "mdi:office-building",
          txt: "caracteristica.1.txt",
        },
        {
          codigo: 2,
          name: "caracteristica.2.name",
          icono: "mdi:puzzle-check",
          txt: "caracteristica.2.txt",
        },
        {
          codigo: 3,
          name: "caracteristica.3.name",
          icono: "fluent:target-arrow-16-filled",
          txt: "caracteristica.3.txt",
        },
        {
          codigo: 4,
          name: "caracteristica.4.name",
          icono: "octicon:graph-16",
          txt: "caracteristica.4.txt",
        },
        {
          codigo: 5,
          name: "caracteristica.5.name",
          icono: "bi:credit-card-fill",
          txt: "caracteristica.5.txt",
        },
        {
          codigo: 6,
          name: "caracteristica.6.name",
          icono: "mdi-magnify",
          txt: "caracteristica.6.txt",
        },
        {
          codigo: 7,
          name: "caracteristica.7.name",
          icono: "mdi-currency-usd",
          txt: "caracteristica.7.txt",
        },
        {
          codigo: 8,
          name: "caracteristica.8.name",
          icono: "mdi-text-box-search",
          txt: "caracteristica.8.txt",
        },
        {
          codigo: 9,
          name: "caracteristica.9.name",
          icono: "mdi-cogs",
          txt: "caracteristica.9.txt",
        },
      ],
      condiciones: [
        {
          codigo: 1,
          name: "condicion.1.name",
          icono: "mdi-cake-variant",
        },
        {
          codigo: 2,
          name: "condicion.2.name",
          icono: "mdi-party-popper",
        },
        {
          codigo: 3,
          name: "condicion.3.name",
          icono: "mdi-human-male-female",
        },
        {
          codigo: 4,
          name: "condicion.4.name",
          icono: "mdi-gender-male-female",
        },
        {
          codigo: 5,
          name: "condicion.5.name",
          icono: "mdi-seal-variant",
        },
        {
          codigo: 6,
          name: "condicion.6.name",
          icono: "mdi-gift",
        },
        {
          codigo: 7,
          name: "condicion.7.name",
          icono: "mdi-bike-fast",
        },
        {
          codigo: 8,
          name: "condicion.8.name",
          icono: "mdi-calendar-month",
        },
        {
          codigo: 9,
          name: "condicion.9.name",
          icono: "mdi-timer",
        },
        {
          codigo: 10,
          name: "condicion.10.name",
          icono: "mdi-ticket",
        },
        {
          codigo: 11,
          name: "condicion.11.name",
          icono: "mdi-human-capacity-increase",
        },
        {
          codigo: 12,
          name: "condicion.12.name",
          icono: "mdi-store",
        },
        {
          codigo: 13,
          name: "condicion.13.name",
          icono: "mdi-cash",
        },
        {
          codigo: 14,
          name: "condicion.14.name",
          icono: "mdi-cart",
        },
        {
          codigo: 15,
          name: "condicion.15.name",
          icono: "mdi-clipboard-arrow-up",
        },
        {
          codigo: 16,
          name: "condicion.16.name",
          icono: "mdi-account-arrow-up",
        },
        {
          codigo: 17,
          name: "condicion.17.name",
          icono: "mdi-account-arrow-down",
        },
      ],
    };
  },
};
</script>

<style scoped>
p,
h1,
span {
  color: white;
}
.us-about {
  background-image: linear-gradient(
    140deg,
    hsl(272deg 58% 36%) 0%,
    hsl(272deg 51% 40%) 8%,
    hsl(272deg 45% 45%) 15%,
    hsl(272deg 41% 49%) 23%,
    hsl(272deg 42% 53%) 29%,
    hsl(272deg 45% 57%) 35%,
    hsl(272deg 49% 61%) 39%,
    hsl(272deg 54% 65%) 43%,
    hsl(272deg 61% 70%) 46%,
    hsl(272deg 69% 74%) 48%,
    hsl(272deg 82% 78%) 51%,
    hsl(272deg 100% 83%) 54%,
    hsl(272deg 80% 78%) 57%,
    hsl(272deg 66% 73%) 61%,
    hsl(272deg 57% 68%) 65%,
    hsl(272deg 51% 64%) 69%,
    hsl(272deg 45% 59%) 73%,
    hsl(272deg 41% 55%) 77%,
    hsl(272deg 38% 50%) 81%,
    hsl(272deg 42% 46%) 86%,
    hsl(272deg 47% 41%) 90%,
    hsl(272deg 53% 37%) 95%,
    hsl(272deg 61% 32%) 100%
  );

  background-size: 100% 300%;
  animation: change 10s ease;
  -webkit-animation: change 10s ease;
  -moz-animation: change 10s ease;
}

@-webkit-keyframes change {
  0% {
    background-position: 86% 100%;
  }
  50% {
    background-position: 15% 0%;
  }
  100% {
    background-position: 15% 0%;
  }
}
@-moz-keyframes change {
  0% {
    background-position: 86% 100%;
  }
  50% {
    background-position: 15% 0%;
  }
  100% {
    background-position: 15% 0%;
  }
}
@keyframes change {
  0% {
    background-position: 86% 100%;
  }
  50% {
    background-position: 15% 0%;
  }
  100% {
    background-position: 15% 0%;
  }
}

.container2 {
  width: 100%;
}
.container3 {
  width: 100%;
}
.margen-container {
  margin-top: 2rem;
}
.margen-imagenes {
  margin: 1rem;
}

.imagen-efecto:hover {
  position: relative;
  box-shadow: 0 14px 26px rgba(0, 0, 0, 0.04);
  transition: all 0.3s ease-out;
  transform: translateY(-5px) scale(1.005) translateZ(0);
  box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11),
    0 24px 46px var(--box-shadow-color);
}

.card:hover .overlay {
  transform: scale(4) translateZ(0);
}
.primera-seccion {
  margin-bottom: 3rem;
  border-bottom: 1px solid #9999cc;
}
.icons-seccion {
  margin-bottom: 3rem;
}
#card-img {
  border-radius: 8px;
}
#card-img5 {
  border-radius: 8px;
}
.panel-admin {
  border-radius: 8px;
  -webkit-mask-image: -webkit-linear-gradient(
    -90deg,
    rgba(0, 0, 0, 1) 40%,
    rgba(0, 0, 0, 0) 85%
  );
  mask-image: linear-gradient(rgba(0, 0, 0, 1) 40%, rgba(0, 0, 0, 0) 85%);
}
.text-panel-admin {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#text1-style {
  font-size: 1.5rem;
  line-height: 1.8;
}
#text-special-style {
  font-size: 1.5rem;
  line-height: 1.8;
}
#text-style {
  font-size: 1.2rem;
  line-height: 1.5;
}

.grid-4cols {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(4, 1fr);
}

@media screen and (max-width: 1024px) {
  .grid-4cols {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .grid-4cols {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 600px) {
  .grid-4cols {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0px;
  }
}
</style>
