<template>
  <div>
    <div class="card row-mega">
      <div class="col-a">
        <img :src="card.imagen" :alt="$t(card.alt)" class="activator" />
      </div>

      <div class="col-b row-body-card">
        <h5 id="team-card-text" class="activator">{{ $t(card.nombre) }}</h5>
        <!-- <h4 id="team-card-text" class="activator">{{ $t(card.titulo) }}</h4> -->
        <span>{{ $t(card.card) }} </span>
        <p>
          {{ $t(card.texto)
          }}<a
            href="https://www.remef.org.mx/index.php/remef/article/view/570"
            target="_blank"
            style="font-weight: bolder"
            >{{ $t(card.textoa) }}</a
          >{{ $t(card.texto2) }}
        </p>
      </div>
      <!-- <div class="card-reveal">
            <span class="card-title"
              >{{ $t("cards.card") }}
              <i class="material-icons right">close</i></span
            >
            {{ $t(card.texto) }}
          </div> -->
    </div>
  </div>
</template>
<script>
export default {
  watch: {},
  props: {
    card: { type: Object },
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.card-image {
  width: 100%;
  max-width: 400px;
  height: auto;
}
h5 {
  color: black;
}
h4 {
  font-size: 17px;
  color: black;
}
p {
  color: black;
  font-size: 1.2rem;
  font-family: "Noto Sans Light";
  font-weight: 400;
  word-break: normal;
  text-shadow: 0 0 1px rgb(255, 255, 255);
}
.card {
  border-radius: 10px;
  /* background-image: linear-gradient(
    310deg,
    hsl(272deg 58% 36%) 1%,
    hsl(299deg 59% 34%) 32%,
    hsl(318deg 63% 41%) 40%,
    hsl(331deg 59% 48%) 45%,
    hsl(342deg 64% 55%) 48%,
    hsl(355deg 72% 62%) 50%,
    hsl(8deg 80% 65%) 51%,
    hsl(18deg 86% 65%) 51%,
    hsl(27deg 87% 66%) 51%,
    hsl(35deg 85% 67%) 51%,
    hsl(45deg 80% 68%) 50%,
    hsl(57deg 70% 71%) 50%,
    hsl(45deg 78% 68%) 50%,
    hsl(35deg 83% 66%) 49%,
    hsl(27deg 83% 65%) 49%,
    hsl(18deg 80% 64%) 49%,
    hsl(8deg 74% 63%) 49%,
    hsl(355deg 65% 60%) 50%,
    hsl(342deg 58% 52%) 52%,
    hsl(330deg 61% 45%) 55%,
    hsl(317deg 66% 37%) 60%,
    hsl(299deg 63% 31%) 68%,
    hsl(272deg 61% 32%) 99%
  ); */
  /* background: linear-gradient(-135deg, rgb(34, 76, 152) 10%, transparent),
    repeating-linear-gradient(
      45deg,
      rgba(34, 76, 152, 1) 0%,
      rgba(31, 48, 94, 0.6) 1%,
      transparent 1%,
      transparent 20%
    ),
    repeating-linear-gradient(
      -45deg,
      rgba(34, 76, 152, 0.4) 0%,
      rgba(31, 48, 94, 0.5) 1%,
      transparent 1%,
      transparent 20%
    ); */
  background-image: linear-gradient(
    305deg,
    hsl(34deg 78% 91%) 1%,
    hsl(34deg 56% 89%) 32%,
    hsl(34deg 42% 87%) 40%,
    hsl(34deg 32% 85%) 45%,
    hsl(34deg 24% 82%) 48%,
    hsl(34deg 19% 80%) 50%,
    hsl(34deg 14% 78%) 51%,
    hsl(34deg 10% 76%) 51%,
    hsl(34deg 7% 74%) 51%,
    hsl(33deg 4% 71%) 51%,
    hsl(33deg 2% 69%) 50%,
    hsl(0deg 0% 67%) 50%,
    hsl(344deg 0% 70%) 50%,
    hsl(344deg 0% 72%) 49%,
    hsl(344deg 0% 74%) 49%,
    hsl(344deg 0% 77%) 49%,
    hsl(344deg 0% 79%) 49%,
    hsl(344deg 0% 82%) 50%,
    hsl(344deg 0% 84%) 52%,
    hsl(344deg 0% 87%) 55%,
    hsl(344deg 0% 89%) 60%,
    hsl(344deg 0% 92%) 68%,
    hsl(0deg 0% 94%) 99%
  );
  background-color: rgba(34, 76, 152, 0.25);
  box-shadow: 5px 5px 5px;

  background-size: 100% 510%;
  animation: change 10s ease;
  -webkit-animation: change 10s ease;
  -moz-animation: change 10s ease;
}

@-webkit-keyframes change {
  0% {
    background-position: 86% 100%;
  }
  50% {
    background-position: 15% 0%;
  }
  100% {
    background-position: 15% 0%;
  }
}

@-moz-keyframes change {
  0% {
    background-position: 86% 100%;
  }
  50% {
    background-position: 15% 0%;
  }
  100% {
    background-position: 15% 0%;
  }
}
@keyframes change {
  0% {
    background-position: 86% 100%;
  }
  50% {
    background-position: 15% 0%;
  }
  100% {
    background-position: 15% 0%;
  }
}

#team-card-text {
  margin-left: 0.7rem;
  margin-top: 0.5rem;
  padding-top: 10px;
  padding-left: 5px;
}
#title-card {
  font-size: 1.2rem;
  font-family: "Cascadia Code";
  font-weight: 800;
}

.row-mega {
  display: flex;
  flex-direction: row;
}
.col-a {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  padding: 0%;
  padding-right: 1rem;
}
.col-b {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 2;
  padding: 0%;
  padding-right: 1rem;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.row-body-card {
  margin-left: 1rem;
  margin-right: 1rem;
}
a {
  text-decoration: underline;
}
img {
  width: 100%;
  max-width: 290px;
  min-width: 280px;

  height: 100%;
  border-radius: 10px 0px 0px 10px;
}
</style>
