<template>
  <div id="documentation">
    <Navbar></Navbar>
    <main>
      <div class="container">
        <h1 v-t="'documentacion.seccion1_titulo1'"></h1>
        <p v-t="'documentacion.seccion1_texto1'">
        </p>
        <a class="waves-effect waves-light btn btn-local" >{{$t("documentacion.seccion1_boton")}}</a>
      </div>

        <div class="row row-space">
          <div class="col fondo-documentacion s12 m12 g12 l12">
            <div class="container">
              <p>
                {{$t("documentacion.seccion2_texto1")}}<br><br>

                {{$t("documentacion.seccion2_texto2")}}
              </p>
              <sequential-entrance fromRight>
                <div class="col s12 m6 g3 l3" v-for="card in cards" :key="card.titulo">
                  <div class="card card-background">
                    <div class="card-content white-text">
                      <span class="card-title center-align"><i class="medium material-icons">{{$t(card.icono)}}</i></span>
                      <span class="card-title center-align">{{$t(card.titulo)}}</span>
                      <p class="center-align">{{$t(card.texto)}}</p>
                    </div>
                  </div>
                </div>
              </sequential-entrance>            
            </div>  
          </div>
        </div>


      <div class="container">
        <h2 v-t="'documentacion.seccion3_titulo'"></h2>
        <div class="row">
          <div class="col s12 m4 g4 l4">
            <h3 v-t="'documentacion.seccio3_columna1_titulo'"></h3>
            <p v-t="'documentacion.seccio3_columna1_texto'"></p>
          </div>
          <div class="col s12 m4 g4 l4">
            <h3 v-t="'documentacion.seccio3_columna2_titulo'"></h3>
            <p v-t="'documentacion.seccio3_columna2_texto'"></p>
          </div>
          <div class="col s12 m4 g4 l4">
            <h3 v-t="'documentacion.seccio3_columna3_titulo'"></h3>
            <p v-t="'documentacion.seccio3_columna3_texto'"></p>
          </div>
        </div>
      </div>
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from '../components/navbar.vue'
import Footer from '../components/footer.vue'
export default {
  props: {
  },
  name: 'documentation',
  components:{
    Navbar,
    Footer
  },
  data(){
    return{
      cards:[
        {
          titulo:"documentacion.cards.1.titulo",
          icono: "done_all",
          texto:"documentacion.cards.1.texto"
        },
        {
          titulo:"documentacion.cards.2.titulo",
          icono: "https",
          texto:"documentacion.cards.2.texto"
        },
        {
          titulo:"documentacion.cards.3.titulo",
          icono: "important_devices",
          texto:"documentacion.cards.3.texto"
        },
        {
          titulo:"documentacion.cards.4.titulo",
          icono: "cloud_done",
          texto:"documentacion.cards.4.texto"
        }
      ]
    }
  }
}
</script>

<style>
.btn-local{
  background-color:#4A235A;
}
.btn-local:hover {
  background-color: #6633CC;
  color:antiquewhite;
}
.fondo-documentacion{
  color:antiquewhite;
  background: linear-gradient(to bottom right, #4A148C, #536DFE);
}
.card-background{
  background: rgba(26, 130, 248, 0.2);
  border-radius: 10px;
}
.row-space{
  margin-top:1rem;
  margin-bottom: 1rem;
}
</style>
