import Vue from "vue";
import Router from "vue-router";
import Home from "./views/home.vue";
import About from "./views/about.vue";
import Contact from "./views/contact.vue";
import Documentation from "./views/documentation.vue";
import Codigo from "./views/codigo.vue";
import Projects from "./views/projects.vue";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/documentation",
      name: "documentation",
      component: Documentation,
    },
    {
      path: "/codigo",
      name: "codigo",
      component: Codigo,
    },
    {
      path: "/projects",
      name: "projects",
      component: Projects,
    },
    {
      path: "/about",
      name: "about",
      component: About,
    },
    // {
    //   path: "/contact-us",
    //   name: "contact",
    //   component: Contact,
    // },
  ],
});
