<template>
  <footer class="page-footer bg-main">
    <div class="container">
      <div class="row">
        <div class="col s6">
          <a href="/" class="logo sin_subrayado">fiexact</a>
        </div>
        <!--<div v-for="columna in columnas" :key="columna.id" class="col s6">
          <div :class="clase">
            <h5 v-if="columna.titulo.logo != true" class="white-text">
              {{ $t(columna.titulo.label) }}
            </h5>

             <a href="https://loyalty.fiexact.com/api/docs" target="_blank"
              >Documentación fiexact loyalty</a
            > -->
            <!-- <ul v-if="columna.links.length > 0">
              <li v-for="item in columna.links" :key="item.label">
                <a
                  class="grey-text text-lighten-3"
                  href="item.liga"
                  @click="redirect(item.liga)"
                  >{{ $t(item.label) }}</a
                >
              </li>
            </ul> 
          </div>
        </div>-->
      </div>
    </div>
    <div class="footer-copyright"></div>
  </footer>
</template>
<script>
import documentation from "../views/documentation.vue";
export default {
  components: { documentation },
  data() {
    return {
      clase: "",
      columnas: [
        {
          id: 1,
          titulo: {
            logo: false,
            label: "footer.documentacion",
            link: "https://loyalty.fiexact.com/api/docs",
          },
          links: [
            {
              label: "",
              link: "",
            },
          ],
        },
        // {
        //   id: 3,
        //   titulo: {
        //     logo: false,
        //     label: "footer.3.label",
        //   },
        //   links: [
        //     {
        //       label: "footer.3.1.label",
        //       liga: "",
        //     },
        //     {
        //       label: "footer.3.2.label",
        //       liga: "",
        //     },
        //     {
        //       label: "footer.3.3.label",
        //       link: "",
        //     },
        //     {
        //       label: "footer.3.4.label",
        //       link: "",
        //     },
        //   ],
        // },
        // {
        //   id: 5,
        //   titulo: {
        //     label: "footer.4.label",
        //   },
        //   links: [
        //     {
        //       label: "footer.4.1.label",
        //       liga: "",
        //     },
        //     {
        //       label: "footer.4.2.label",
        //       liga: "",
        //     },
        //     {
        //       label: "footer.4.3.label",
        //       liga: "",
        //     },
        //     {
        //       label: "footer.4.4.label",
        //       liga: "",
        //     },
        //   ],
        // },
      ],
    };
  },
  created() {
    if (this.columnas.length == 2) {
      this.clase = "col m6 l6 s12";
    }
    if (this.columnas.length == 3) {
      this.clase = "col m4 l4 s12";
    }
    if (this.columnas.length == 4) {
      this.clase = "col m3 l3 s12";
    }
  },
  methods: {
    redirect(ruta) {
      this.$router.push({
        path: ruta,
      });
    },
  },
};
</script>

<style scoped>
.logo {
  font-family: "IBM Plex Mono", monospace !important;
  /*font-family: 'Hack-Bold';*/
  font-weight: Bold;
  font-size: 2.1rem;
}

.logo:after {
  content: "_";
}

body:hover .logo:after {
  animation: 1s blink linear 5;
}
</style>
