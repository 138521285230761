<template>
	<div>
		<!-- <ul id="dropdown1" class="dropdown-content">
      <li>
        <a href="#" @click="setLocale('en')"><flag name iso="us" /> English</a>
      </li>
      <li>
        <a href="#" @click="setLocale('es')"> <flag name iso="mx" /> Español</a>
      </li>
    </ul> -->
		<nav>
			<div class="nav-wrapper container">
				<a href="/" class="brand-logo sin_subrayado">fiexact</a>
				<a href="#" data-target="mobile-demo" class="sidenav-trigger start"
					><i class="material-icons">menu</i></a
				>
				<ul id="nav-mobile" class="right hide-on-med-and-down">
					<li v-for="item in opcionesMenu" :key="item.link">
						<a v-if="item.flag == false" v-on:click="redirect(item.link)">{{
							$t(item.label)
						}}</a>
					</li>
					<!--<li><a class="dropdown-trigger" href="#" data-target="dropdown1"><img :src="imagen(locale)" alt="" :class="tamanioImagen(locale)"><i class="material-icons right">arrow_drop_down</i></a></li>-->
					<!-- <li>
            <md-menu
              md-direction="bottom-start"
              md-size="small"
              md-close-on-click
            >
              <md-button class="md-icon-button" md-menu-trigger
                ><img
                  :src="imagen(locale)"
                  alt=""
                  :class="tamanioImagen(locale)"
              /></md-button>
              <md-menu-content>
                <md-menu-item
                  ><flag iso="us" /><a href="#" @click="setLocale('en')"
                    >English</a
                  ></md-menu-item
                >
                <md-menu-item
                  ><flag iso="mx" />
                  <a href="#" @click="setLocale('es')">
                    Español</a
                  ></md-menu-item
                >
              </md-menu-content>
            </md-menu>
          </li> -->
				</ul>
				<ul class="sidenav" id="mobile-demo">
					<li v-for="item in opcionesMenu" :key="item.link">
						<a v-if="item.flag == false" v-on:click="redirect(item.link)">{{
							$t(item.label)
						}}</a>
					</li>
					<li>
						<!-- <a class="dropdown-trigger" href="#" data-target="dropdown1"
              ><img
                :src="imagen(locale)"
                alt=""
                :class="tamanioImagen(locale)"
              /><i class="material-icons right">arrow_drop_down</i></a
            > -->
					</li>
				</ul>
			</div>
		</nav>
	</div>
</template>
<script>
export default {
	components: {},
	data() {
		return {
			locale: "",
			opcionesMenu: [
				// {
				//   flag: false,
				//   label: "navBar.documentacion",
				//   link: "documentation",
				// },
				// {
				//   flag: false,
				//   label: "navBar.codigo",
				//   link: "codigo",
				// },
				{
					flag: false,
					label: "navBar.proyectos",
					link: "projects",
				},
				{
					flag: false,
					label: "navBar.nosotros",
					link: "about",
				},
				// {
				//   flag: false,
				//   label: "navBar.contact",
				//   link: "contact-us",
				// },
			],
		};
	},
	mounted() {
		this.locale = this.getLocale();
		// window.$(".dropdown-trigger").dropdown();
	},
	methods: {
		getLocale() {
			var idioma = "";
			if (!localStorage.getItem("lang")) {
				localStorage.setItem("lang", "es");
				idioma = "es";
			} else {
				idioma = localStorage.getItem("lang");
			}
			//console.log("consultamos en mounted idioma", idioma);
			return idioma;
		},
		redirect(ruta) {
			//  console.log("Debo ir a la ruta", ruta);
			this.$router.push({
				path: ruta,
			});
			//window.location.reload();
			this.$router.go(0);
		},
		setLocale(locale) {
			//  console.log("Cambiamos idioma a: ", locale);
			this.locale = locale;
			this.$i18n.locale = locale;
			localStorage.setItem("lang", locale);
		},
		tamanioImagen(locale) {
			if (localStorage.getItem("lang") == locale) {
				return "imagenActiva";
			} else {
				return "imagen";
			}
		},
		imagen(locale) {
			if (locale == "es") {
				return "./img/flag_mx.png";
			} else {
				return "./img/flag_uuee.png";
			}
		},
		mostrarbandera(locale) {
			if (localStorage.getItem("lang") == locale) {
				return false;
			} else {
				return true;
			}
		},
	},
};
</script>
<style>
.imagen {
	/*height: 20px;
  width: 20px;*/
	height: 30px;
	width: 30px;
}
.imagenActiva {
	height: 30px;
	width: 30px;
}
.md-menu {
	margin-top: 0.7rem !important;
}

.md-menu-content-container .md-list {
	padding-top: 50px !important;
}

.md-button .md-ripple {
	/*padding: 30px 15px;*/
	color: #ffff !important;
}

.md-list .md-theme-default {
	background: #ffff;
}

.bm-burger-button {
	position: fixed;
	width: 36px;
	height: 30px;
	left: 36px;
	top: 36px;
	cursor: pointer;
}
.bm-burger-bars {
	background-color: #373a47;
}
.line-style {
	position: absolute;
	height: 20%;
	left: 0;
	right: 0;
}
.cross-style {
	position: absolute;
	top: 12px;
	right: 2px;
	cursor: pointer;
}
.bm-cross {
	background: #bdc3c7;
}
.bm-cross-button {
	height: 24px;
	width: 24px;
}
.bm-menu {
	height: 100%; /* 100% Full-height */
	width: 0; /* 0 width - change this with JavaScript */
	position: fixed; /* Stay in place */
	z-index: 1000; /* Stay on top */
	top: 0;
	left: 0;
	background-color: rgb(63, 63, 65); /* Black*/
	overflow-x: hidden; /* Disable horizontal scroll */
	padding-top: 60px; /* Place content 60px from the top */
	transition: 0.5s; /*0.5 second transition effect to slide in the sidenav*/
}

.bm-overlay {
	background: rgba(0, 0, 0, 0.3);
}
.bm-item-list {
	color: #b8b7ad;
	margin-left: 10%;
	font-size: 20px;
}
.bm-item-list > * {
	display: flex;
	text-decoration: none;
	padding: 0.7em;
}
.bm-item-list > * > span {
	margin-left: 10px;
	font-weight: 700;
	color: white;
}

button:focus {
	background-color: #712fd2 !important;
}
</style>
