import Vue from 'vue'
// import VueMaterial from 'vue-material'
// import 'vue-material/dist/vue-material.css'
// import "vue-material/dist/theme/engine"
import App from './App.vue'
import router from './router'
import i18n from './i18n'
// import FlagIcon from 'vue-flag-icon'

// const lang = localStorage.getItem('lang') || 'es';
import SequentialEntrance from "vue-sequential-entrance";
import "vue-sequential-entrance/vue-sequential-entrance.css";
// Vue.use(VueMaterial)

Vue.use(SequentialEntrance);
// Vue.use(FlagIcon);
Vue.config.productionTip = false


new Vue({
  router,
  i18n,
  SequentialEntrance,
  render: h => h(App),
}).$mount('#app')
