<template>
  <div id="codigo">
     <Navbar></Navbar>
    <main>
      <div class="container">
       <div class="row">
         <div class="col s6 offset-s3">
           <img src="img/enConstruccion.jpg" id="img1" width="100%" alt="">
         </div>
       </div>
      </div>
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from '../components/navbar.vue';
import Footer from '../components/footer.vue';
export default {
  components:{
    Navbar,
    Footer
  },
  props: {
  },
  name: 'codigo'
}
</script>

<style  scoped>

#img1{
  margin-top: 10rem;
  margin-bottom: 10rem;
}

</style>
