<template>
  <div>
    <div id="textA-style">
      <span
        class="iconify icons-caracteristica"
        :data-icon="condicion.icono"
      ></span>

      <p>{{ $t(condicion.name) }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "condiciones-grid",
  props: {
    condicion: {
      type: Object,
    },
  },
};
</script>

<style scoped>
.icons-caracteristica {
  font-size: 5rem;
}
#textA-style {
  font-size: 1.3rem;
  text-align: center;
  line-height: 1.8;
}
</style>